import React from "react";
import { Link } from "react-router-dom";

function PaymentSuccess(props) {
  props.$setNode({
    props,
  });

  return (
    <div className="page-wraper">
      <div className="browse-job login-style3">
        <div
          className="bg-img-fix overflow-hidden"
          style={{ backgroundImage: "url(" + imagepaths.loginBg + ")" }}
        >
          <div className="parent-container gradient_bg row gx-0">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="logo_header py-3 ps-5">
                <Link to={"/open/login"} className="logo">
                  <img src={imagepaths.loginWhiteBg} alt="" />
                </Link>
              </div>
            </div>
            <div className="col-12">
              <section class="payment_section">
                <div class="payment_card">
                  <img
                    src={imagepaths.redGradientCheckIcon}
                    className="img-fluid"
                    alt="icon"
                  />
                  <h1>Congratulation!</h1>
                  <p>Your payment is successfully done.</p>
                  <a href="/" class="theme-btn btn-style-one hover-gold">
                    <span>Close</span>
                  </a>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default constore(PaymentSuccess);
